@include topPanel(top-panel-digital-experiences, 600px, $backdrop_usecase_digital_experiences);
@include keyPoints(keypoints-digital-experiences, $backdrop_usecase_digital_experiences);


@include topPanel(top-panel-ai, 600px, $backdrop_usecase_ai);
@include keyPoints(keypoints-ai, $backdrop_usecase_ai);


@include topPanel(top-panel-healthcare, 600px, $backdrop_usecase_healthcare);
@include keyPoints(keypoints-healthcare, $backdrop_usecase_healthcare);


@include topPanel(top-panel-travel, 600px, $backdrop_usecase_travel);
@include keyPoints(keypoints-travel, $backdrop_usecase_travel);


.content-section.ps .row.point p
{
  font-size: 1.2em;
  color: #5A5A5A;
  font-weight: 500;
}

.content-section.ps ul.sidelist
{
  margin-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.content-section.ps ul.sidelist li
{
  //margin-bottom: 40px;
}

.content-section.ps ul.sidelist li p.slh
{
  font-size: 1.0em;
  margin-bottom: 0px;
  color: #cd6133;
}

.content-section.ps ul.sidelist li p.slh i
{
  font-size: 0.4em;
  margin-right: 8px;
  vertical-align: middle;
}

.content-section.ps ul.sidelist li p.sld
{
  font-size: 0.85em !important;
  margin-bottom: 30px;
  color: #888;
}

/*** **/

.content-section.ps ul.bodylist
{
  margin-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.content-section.ps ul.bodylist li
{
  //margin-bottom: 40px;
}

.content-section.ps ul.bodylist li p.slh
{
  font-size: 1.0em;
  margin-bottom: 0px;
  color: #cd6133;
}

.content-section.ps ul.bodylist li p.slh i
{
  font-size: 0.4em;
  margin-right: 8px;
  vertical-align: middle;
}

.content-section.ps ul.bodylist li p.sld
{
  font-size: 18px !important;
  margin-bottom: 20px;
  color: #333;
  font-weight: 400;
  margin-left: 16px;
}

.usecase-bottom
{
  margin: 0px;
  margin-left: -30px;
  margin-right: -30px;
  padding: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 0px;
  margin-bottom: 0px;
  background: radial-gradient(circle, $backdrop_pricing, $backdrop_pricing);
  align-self: center;
}

.usecase-bottom .usecase-bottom-left
{
  padding-left: 75px;
}

.usecase-bottom .usecase-bottom-left h3
{
  color: white;
  margin-bottom: 20px;
}
.usecase-bottom .usecase-bottom-left p
{
  color: white;
  font-size: 1.1em;
}

.usecase-bottom .usecase-bottom-right
{
  align-self: center;
  text-align: center;
}

.usecase-bottom .btn.btn-outline-primary.rounder
{
  background-color: #2f95c7!important;
  color: #fff!important;
  border-color: #25769e!important;
}